<template>
  <div>
    <div v-if="blocks" class="container mt-4">
      <div v-if="$auth.user.paymentType !== 'ELNM'">
      <h3 class="mb-4">Book Your Teaching Sessions</h3>
    </div>
      <template v-if="currentInt">
        <h5>Current month subscription period from: {{ currentInt.start.setLocale('en-gb').toLocaleString() }} to {{ currentInt.end.setLocale('en-gb').toLocaleString() }}</h5>
        <iris-appointment-block v-bind="appointmentBlockProps" :interval="currentInt" @customCalendersSet="updateCalender" />
      </template>
      <template v-if="nextInt">
        <h5>Month 2 subscription period from: {{ nextInt.start.setLocale('en-gb').toLocaleString() }} to {{ nextInt.end.setLocale('en-gb').toLocaleString() }} </h5>
        <iris-appointment-block v-bind="appointmentBlockProps" :interval="nextInt" @customCalendersSet="updateCalender" />
      </template>
      <template v-if="nextInt3">
        <h5>Month 3 subscription period from: {{ nextInt3.start.setLocale('en-gb').toLocaleString() }} to {{ nextInt3.end.setLocale('en-gb').toLocaleString() }} </h5>
        <iris-appointment-block v-bind="appointmentBlockProps" :interval="nextInt3" @customCalendersSet="updateCalender" />
      </template>
      <template v-if="extraPurchasedAppointments?.length">
        <div v-if="$auth.user.paymentType !== 'ELNM'">
          <h5>Extra Teaching Session Purchases:</h5>
        </div>
        <template v-for="extra of extraPurchasedAppointments" >
          <h6>Your session{{ extra.numberOfSessionsPurchases > 1 ? "s" : "" }} {{ extra.numberOfSessionsPurchases > 1 ? "were" : "was" }} purchased on: {{ formatDate(extra.datePurchased) }}</h6>
          <iris-appointment-block @slotsUnbooked="numberOfSlots => updatePaidSlotsAvailable(extra.id, numberOfSlots)" :key="extra.id" v-bind="appointmentBlockProps" :slots-available="extra.numberOfSessionsPurchases" :invoice-id="extra.id" />
        </template>
      </template>
      <div v-if="blocks.extraSessionCost.length > 0">
        <IrisPurchaseAdditionalSessions v-if="allExtraSessionsAllocated" :sessionPricePoints="blocks.extraSessionCost" :token-info="blocks.tokenInfo" :payment-methods="blocks.paymentMethods" :subscriberId="user.subscriberId" @sessionsPurchased="sessionsPurchased" />
        <div v-else class="AskisMember"><b-button
          :to="{ name: 'index' }"
          exact-active-class="active"
          variant="primary"
          class="logoudtBtn"
          v-b-tooltip.hover
          >Exit</b-button>
        </div>        
      </div>
    </div>
    <b-container v-else class="text-center">
      <b-spinner />
    </b-container>
    <div class="container mt-4" v-if="blockError">{{ blockError }}</div>
  </div>
</template>

<style scoped>
.card {
    margin-bottom: 25px;
}
.logoudtBtn {
  width: 160px;
 }
 .AskisMember {
  margin-top:unset;
 }
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { TutorCalendarsFragment } from '~~/iris/src/queries'

export default defineComponent({
  middleware: ['loginWithRefreshToken', 'auth', 'parent'],
  head: {
    title: "Estia Tuition - Book Teaching Sessions"
  }
});
</script>

<script setup lang="ts">
/// <reference path="../../.nuxt/types/nitro-routes.d.ts" />

import { DateTime, Interval } from 'luxon'
import { useFetch } from '@/utils/auth'
import type {InternalApi} from 'nitropack'

const { proxy: { $auth: { user } } } = getCurrentInstance()!

const updatePaidSlotsAvailable = (id: string, numberOfslots: number) => {
  paidSlotsAvailable.value = {
    ...paidSlotsAvailable.value,
    [id]: numberOfslots
  }
}

const paidSlotsAvailable = ref<Record<string, number>>({})

type AppointmentBlockProps = {
        /** subscriber id */
        subscriberId: number
        /** subscribers email */
        email: string
        /** first name */
        firstName: string
        /** last name  */
        lastName: string
        /** number of slots available */
        slotsAvailable: number
        /** time period allowed */
        interval?: Interval
        /** optional invoice id */
        invoiceId?: string
        /** plan string */
        planCode: string
        /** show iris connect links */
        showConnectLinks?: boolean
        /** fetchCalendarsPromise */
        customCalendersPromise?: Promise<TutorCalendarsFragment>
}

const { isFetching: blocksFetching, error: blockError, data: blocks, execute: refreshAccount } = useFetch(computed(() => `/api/subscription/${user.subscriberId}`)).json<InternalApi['/api/subscription/:subscriberId']['get'] | null>()

const customCalendersPromise = ref<Promise<TutorCalendarsFragment> | undefined>()

const updateCalender = (value: Promise<TutorCalendarsFragment> | undefined) => {
    customCalendersPromise.value = value
}


// data.
// const baseAppointmentBlockProps

const extraPurchasedAppointments = computed(() => {
  return blocks.value?.extraPurchases
})

const appointmentBlockProps = computed<Omit<AppointmentBlockProps, 'interval'>>(() => {
  return {
      subscriberId: user.subscriberId as number,
      email: user.email as string,
      firstName: user.firstName as string,
      lastName: user.lastName as string,
      // interval: Interval.after(DateTime.now().startOf('day'), { weeks: 4 }), // need from recurly
      slotsAvailable: blocks.value?.numberOfSessionsPerPeriod,
      planCode: user.paymentType as string,
      showConnectLinks: true,
      customCalendersPromise: customCalendersPromise.value
  }
})

const currentInt = computed(() => blocks.value?.currentPeriod && blocks.value?.numberOfSessionsPerPeriod && Interval.fromDateTimes(DateTime.fromISO(blocks.value.currentPeriod.start), DateTime.fromISO(blocks.value.currentPeriod.end)))
const nextInt = computed(() => blocks.value?.nextPeriod && blocks.value?.numberOfSessionsPerPeriod && Interval.fromDateTimes(DateTime.fromISO(blocks.value.nextPeriod.start), DateTime.fromISO(blocks.value.nextPeriod.end)))
const nextInt3 = computed(() => blocks.value?.period3 && blocks.value?.numberOfSessionsPerPeriod && Interval.fromDateTimes(DateTime.fromISO(blocks.value.period3.start), DateTime.fromISO(blocks.value.period3.end)))

// watchEffect(() => {
//   console.log(blocks.value)
//   if (currentInt.value) {
//     console.log('current', currentInt.value.start)
//     console.log('current', currentInt.value.end)
//   }
// })

const sessionsPurchased = () => {
  // refresh the backend
  refreshAccount()
}

const formatDate = (dateIso: string) => {
  return DateTime.fromISO(dateIso).setLocale('en-gb').toLocaleString()
}

const allExtraSessionsAllocated = computed(() => {
  for (const key in paidSlotsAvailable.value) {
    if (paidSlotsAvailable.value[key] > 0) {
      return false
    }
  }
  return true
})

  // const props = defineProps<{
  //     /** subscriber id */
  //     subscriberId: number
  //     /** subscribers email */
  //     email: string
  //     /** first name */
  //     firstName: string
  //     /** last name  */
  //     lastName: string
  //     /** number of slots available */
  //     slotsAvailable: number
  //     /** time period allowed */
  //     interval: Interval
  // }>()


</script>