<template>
  <div v-if="$store.getters.requiresDirectDebitFirstDate">
    <h4 v-if="$store.getters.isSubscriptionPlanDec2021">Direct debit details</h4>
    <h4 v-else>{{ $t(paymentMethod) }}</h4>
    <reg-form-select :$v="$v.monthsTerm" v-model="monthsTerm" v-if="!(paymentMethod === 'LTL' && decision === 'DFA') && pmIn('LTL','APS')" label="Term" field-name="Term" placeholder="Choose term" :options="termList" />
    <reg-form-input v-if="pmIn('APS','LTL')" label="Amount" :value="money(financeAmount)" plaintext field-name="Amount" />
    <template v-if="!(paymentMethod === 'LTL' && decision === 'DFA')">
      <reg-form-input v-if="!pmIn('CASH') && monthlyInstallment" label="Monthly Instalment" :value="money(monthlyInstallment)" plaintext field-name="Monthly Installment"  />
      <reg-form-radio v-if="directDebitOptions.length > 1" stacked v-model="recurlyDDPaymentMethodId" label="Ongoing subscription" field-name="bank account or card" :description="`This bank account or card will be used to pay for the monthly payments`">
        <b-form-radio v-for="source in directDebitOptions" :key="source.value" :value="source.value">
          <template v-if="source.type === 'card'">
            SPM <component :is="icons[source.cardType]" v-if="icons[source.cardType]" style="height: 1em; width: 2em;" />
            <span v-else v-text="source.cardType" />
            {{ source.cardFirstSix }}••••••{{ source.cardLastFour }} <span class="text-muted">{{ source.cardExpiryMonth }} / {{ source.cardExpiryYear }}</span>
            <span class="text-warning small d-block" v-if="showExpiryWarning(source.cardExpiryMonth, source.cardExpiryYear)">⚠️ This card has an expiry date before the first payment is due</span>
          </template>
          <template v-else-if="source.type === 'bacs'">
            PPM 🏦 Bank account below
          </template>
          <template v-else v-text="source.type">
          </template>
        </b-form-radio>
      </reg-form-radio>
      <reg-form-input :value="date(firstPaymentDue)" type="text" label="First Instalment Due" field-name="First Instalment Due" plaintext />
      <!-- <reg-form-checkbox v-if="$store.state.regModelMap.INSTITUTECODE === 'EXEDU'" field-name="RSDD" label=" " v-model="directDebitUnableToBeCompletedOnNight" /> -->
      <reg-form-input v-if="pmIn('LTL') && lastInstallmentAmount" label="Last Installment Amount" :value="money(lastInstallmentAmount)" plaintext field-name="Last Installment Amount"  />
      <b-form-group v-if="pmIn('LTL')" label-cols="4" breakpoint="md" label="Application">
        <b-button class="col-md-3" href="https://secure.duologi.net/merchant/account/login/redirect/L21lcmNoYW50" variant="primary" target="_blank">Go to Duologi</b-button>
        <b-button class="col-md-3 ml-3" :href="threeJPosUrl" target="_blank">Go to 3J</b-button>
      </b-form-group>
    </template>
    <reg-form-radio class="finance_decision" v-if="pmIn('LTL')" v-model="decision" :$v="$v.decision" field-name="Finance Decision" label="Decision" :options="financeDecisionOptions"/>
  </div>
</template>

<script>
import Vue from 'vue'
import financeMixins from '../mixins/financeMixins'
import { mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import brandIcons from '@iris/assets/cardLogos'
import _times from 'lodash/times'
import moment from 'moment-timezone'

export default Vue.extend({
  mixins: [financeMixins],
  props: {
    threeJPosUrl: {
      type: String,
      default: ~window.location.hostname.indexOf('exemplar-education.com') ? 'https://3jpos.ccfservices.co.uk/Login.aspx' : 'https://postraining.ccfservices.co.uk/login.aspx'
    },
    $v: {
      type: Object,
      required: true
    },
    financeDecisionOptions: {
      type: Array,
      default: () => [
        { value: 'DFA', text: 'Approved – Duologi' },
        { value: 'FA', text: 'Approved – 3J' },
        { value: 'AWC', text: 'Approved with Conditions - 3J' },
        { value: 'FD', text: 'Declined - 3J' },
        { value: 'FR', text: 'Referred - 3J' }
      ]
    },
    termList: {
      required: false,
      default () {
        return _times(5, i => {
          let term = (i + 1) * 12
          return { value: term, text: `${term} Months` }
        })
      }
    }
  },
  watch: {
    monthsDeposit: {
      handler () {
        // if first payment due is invalid reset it!
        if (this.$v.instalments.firstPaymentDue.$invalid) {
          this.firstPaymentDue = ''
        }
      },
      immediate: true
    },
    directDebitOptions: {
      handler () {
        if (this.directDebitOptions.length && !this.directDebitOptions.map(o => o.value).includes(this.recurlyDDPaymentMethodId)) {
          this.recurlyDDPaymentMethodId = this.directDebitOptions[0].value
        }
      },
      immediate: true
    }
  },
  methods: {
    showExpiryWarning (month, year) {
      if (this.firstPaymentDue) {
        const expiryDate = this.$store.getters.moment().month(month - 1).year(year).endOf('month')
        return expiryDate.isSameOrBefore(this.firstPaymentDue)
      }
      return false
    },
    date (isoDate) {
      return moment(isoDate).format('DD / MM / YYYY')
    }
  },
  computed: {
    directDebitOptions () {
      return [
        { value: null, type: 'bacs' },
        ...this.$store.getters['payments/paymentsWithDollars'].filter(p => !!p.billingMethodId).map(payment => ({
          value: payment.billingMethodId,
          type: 'card',
          cardType: payment.cardType,
          cardFirstSix: payment.cardFirstSix,
          cardLastFour: payment.cardLastFour,
          cardExpiryMonth: payment.cardExpiryMonth,
          cardExpiryYear: payment.cardExpiryYear
        }))
      ].filter(p => !this.currentNewPricingPlan || !this.currentNewPricingPlan.limitDirectDebitTo || this.currentNewPricingPlan.limitDirectDebitTo === p.type)
    },
    icons () {
      return brandIcons
    },
    firstPaymentDue: {
      get () {
        let date = this.$store.getters['instalments/firstPaymentDue']
        if (date) {
          return date.format('YYYY-MM-DD')
        }
        return ''
      },
      set (date) {
        this.$store.commit('instalments/setFirstPaymentDue', date)
      }
    },
    firstPaymentDate () {
      let date = this.$store.getters['instalments/firstPossibleDirectDebitDate']
      if (date) {
        return date.format('YYYY-MM-DD')
      }
      return ''
    },
    lastPaymentDate () {
      let date = this.$store.getters['instalments/lastPossibleDirectDebitDate']
      if (date) {
        return date.format('YYYY-MM-DD')
      }
      return ''
    },
    firstPaymentDateFormatted () {
      let date = this.$store.getters['instalments/firstPossibleDirectDebitDate']
      if (date) {
        return date.format('DD/MM/YYYY')
      }
      return ''
    },
    lastPaymentDateFormatted () {
      let date = this.$store.getters['instalments/lastPossibleDirectDebitDate']
      if (date) {
        return date.format('DD/MM/YYYY')
      }
      return ''
    },
    ...mapState({
      monthsDeposit: state => state.finance.monthsDeposit
    }),
    ...mapGetters([
      'monthlyInstallment',
      'financeAmount',
      'lastInstallmentAmount',
      'currentNewPricingPlan'
    ]),
    ...mapFields([
      'finance.monthsTerm',
      'finance.decision',
      'finance.recurlyDDPaymentMethodId',
      'directDebitUnableToBeCompletedOnNight'
    ])
  }
})
</script>

<style>
  .finance_decision.form-row > div > .custom-radio > .custom-control-inline:nth-child(1) {
    display: block;
  }
</style>
