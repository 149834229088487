<template>
  <div>
    <template v-if="currentNewPricingPlan.includesLearningSystem">
      <h3 v-if="title">{{ title }}</h3>
      <h4 class="text-muted" v-if="!noAddOrRemove && subTitle">{{ subTitle }}</h4>
    </template>
    <child v-for="(child, idx) in children" :no-add-or-remove="noAddOrRemove" :only-course-change="onlyCourseChange" :index="idx" :key="idx" :$v="$v.$each[idx]" />
    <button v-if="!(onlyCourseChange || noAddOrRemove) && children.length < maximumStudents && currentNewPricingPlan.includesLearningSystem" class="btn btn-primary" @click="addChild">{{ addStudentText }}</button>
  </div>
</template>

<script>

import { mapMultiRowFields } from 'vuex-map-fields'
import { mapMutations, mapGetters } from 'vuex'

import child from './Child'

export default {
  components: { child },
  props: {
    $v: {
      type: Object,
      required: true
    },
    onlyCourseChange: {
      type: Boolean,
      default: false
    },
    noAddOrRemove: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Student information'
    }
  },
  computed: {
    ...mapMultiRowFields(['children']),
    ...mapGetters(['currentNewPricingPlan']),
    addStudentText () {
      if (this.currentNewPricingPlan.allowExtraPaidStudents) {
        return `Add Student (extra ${new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(this.currentNewPricingPlan.allowExtraPaidStudents.cost)} per month per student)`
      }
      return `Add Student`
    },
    maximumStudents () {
      if (this.currentNewPricingPlan && this.currentNewPricingPlan.absoluteMaxStudentsLimit) {
        return this.currentNewPricingPlan.absoluteMaxStudentsLimit
      }
      return 5
    },
    subTitle () {
      if (this.maximumStudents > 1) {
        return `You can add up to ${this.maximumStudents} students`
      }
    }
  },
  created () {
    this.$watch('$v.$invalid', (invalid) => {
      this.$emit('invalidity', invalid)
    }, { immediate: true })
  },
  methods: {
    ...mapMutations(['addChild'])
  }
}
</script>
