import { Context } from '@nuxt/types'
import type DynamicConfigRefreshScheme from '../scheme/dynamicRefresh'
import axios from 'axios'

const TOKEN_PARAM_NAME = '_rt'

export default ({ $auth, redirect, query, route }: Context) =>  {
    if (typeof query[TOKEN_PARAM_NAME] === 'string') {
        const stg = $auth.getStrategy() as unknown as DynamicConfigRefreshScheme
        stg.refreshToken.set(query[TOKEN_PARAM_NAME])
        // need to set to some value otherwise it's not considered "valid" ad refreshTokens() won't work
        stg.token.set('UNKNOWN')
        return stg.refreshTokens().then(() => stg.fetchUser()).catch((e) => {
            if (!(axios.isAxiosError(e) && e.response.status === 401)) {
                // if not 401 throw error
                throw e
            }
        }).then(() => {
            return redirect({
                ...route,
                replace: true,
                query: {
                    ...route.query,
                    [TOKEN_PARAM_NAME]: undefined
                }
            })
        })
    }
}
