<template>
  <payment-common>
    <template #header>
      <b-alert variant="primary" v-if="cardAuthActive" show>Please complete card authentication</b-alert>
      <b-alert variant="primary" v-else-if="processing" show>Submitting card payment</b-alert>
      <b-alert variant="warning" v-if="error" show dismissible>{{ error.message }}</b-alert>
      <b-alert variant="success" v-if="internalValue.finalised" show dismissible>Payment successfully processed</b-alert>
    </template>
    <b-form-row v-if="payments" class="mb-3">
      <b-col md="8" offset-md="4">
        <b-card title="Instalments">
          <b-table v-if="payments" :items="payments" :fields="paymentFields" striped responsive small />
        </b-card>
      </b-col>
    </b-form-row>
    <recurly-elements />
    <template #footer v-if="!internalValue.finalised">
      <spinner class="mt-3" v-if="processing" color="rgb(24, 108, 149)" />
      <b-button class="float-right ml-3" variant="primary" @mouseover="$v.paidBy.$touch" @click="processPayment" :disabled="!canEdit || !cardDetailsOk">Process payment for {{ $n(amount, 'currency') }}</b-button>
    </template>
  </payment-common>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, computed } from 'vue'
import { InstalmentType, RecurlyPaymentPlanPayment } from '@iris/store/payments/types'
import paymentMixins from './mixin'
import { IrisStore } from '@iris/store/types'
import { PaymentType } from '@iris/nestjs-interfaces-recurly'
import spinner from 'vue-spinner/src/RiseLoader.vue'
import { BvTableFieldArray } from 'bootstrap-vue/esm'
import { useRecurly } from './recurly'
import RecurlyElements from './RecurlyElements.vue'

const mixin = paymentMixins<RecurlyPaymentPlanPayment>({ includeCanEdit: false })
export default defineComponent({
  extends: mixin,
  components: {
    // this is just html structure not specific to
    spinner,
    RecurlyElements
  },
  setup () {
    const currentInstance = getCurrentInstance()!
    const { public: { recurlyPublicKey } } = useRuntimeConfig()
    const instance = currentInstance.proxy as unknown as InstanceType<typeof mixin>
    const store = instance.$store as IrisStore

    const instalments = 3

    const payments = computed<InstalmentType[]>(() => {
      const payments: InstalmentType[] = []
      const amountInCents = Math.floor(instance.internalValue.amountInCents / instalments)
      for (let i = 0; i < instalments; i++) {
        payments.push({
          date: i === 0 ? 'Today' : store.getters.moment().add(i, 'month').format('YYYY-MM-DD'),
          amountInCents,
          monthNumber: i + 1
        })
      }
      return payments
    })

    const paymentFields: BvTableFieldArray = [
      {
        key: 'monthNumber',
        label: 'Payment'
      },
      {
        key: 'date'
      },
      {
        key: 'amountInCents',
        label: 'Amount',
        formatter: v => instance.$i18n.n(v / 100, 'currency')
      }
    ]

    return {
      ...useRecurly({
        publicKey: recurlyPublicKey,
        value: computed<RecurlyPaymentPlanPayment>({
          get () {
            return instance.internalValue
          },
          set (v) {
            instance.internalValue = v
          }
        }),
        paymentTypeProps: {
          type: PaymentType.INSTALMENTS,
          numberOfInstalments: instalments,
          addons: (instance.$store as IrisStore).getters.currentNewPricingPlan.addons
        }
      }),
      payments,
      paymentFields
    }
  }
})
</script>

<style>

  @import url('https://js.recurly.com/v4/recurly.css');
  /* .form-control.custom-focus {
    color: #495057;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .form-control.is-valid.custom-focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  .form-control.is-invalid.custom-focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .stripe-disabled .form-control {
    background-color: #e9ecef;
  } */

</style>
