import { RecurlyBasePayment } from '@iris/store/payments/types'
import { IAddressInfo, IPersonInfo, IrisStore } from '@iris/store/types'
import { CardElement, ElementsInstance, Recurly, TokenPayload } from '@recurly/recurly-js'
import { computed, getCurrentInstance, onMounted, onUnmounted, reactive, Ref, toRef, unref, watch, WritableComputedRef } from 'vue'
import axios, { AxiosError } from 'axios'
import * as Sentry from '@sentry/browser'
import { IAccountInitialPayment, IAccountInitialPaymentResponse } from '@iris/nestjs-interfaces-recurly'
import externalScript from '@iris/externalScript'

export interface RecurlyFieldChangeResult {
  empty: boolean
  focus: boolean
  valid: boolean
}

export interface RecurlyChangeResult extends RecurlyFieldChangeResult {
  firstSix: string
  lastFour: string
  brand: string
  number: RecurlyFieldChangeResult
  expiry: RecurlyFieldChangeResult
  cvv: RecurlyFieldChangeResult
}

interface RecurlyTransactionError {
  /**
   * Object type
   */
  object: 'transaction_error';
  /**
   * Transaction ID
   */
  transactionId: string;
  /**
   * Category
   */
  category: string;
  /**
   * Code
   */
  code: string;
  /**
   * Customer message
   */
  message: string;
  /**
   * Merchant message
   */
  merchantAdvice: string;
  /**
   * Returned when 3-D Secure authentication is required for a transaction. Pass this value to Recurly.js so it can continue the challenge flow.
   */
  threeDSecureActionTokenId: string;

}

type ExtraOptionsForInitialPayment = Pick<IAccountInitialPayment, 'type' | 'deferredDate' | 'numberOfInstalments' | 'monthsInitialPayment' | 'addons'>

export function useRecurly<R extends RecurlyBasePayment> ({ onSuccessfulPayment, paymentTypeProps, value, publicKey }: {
  /** callback for successful payment to do further processing if required */
  onSuccessfulPayment?: (result: IAccountInitialPaymentResponse) => PromiseLike<void> | void
  /** extra properties for payment api */
  paymentTypeProps: Ref<ExtraOptionsForInitialPayment> | ExtraOptionsForInitialPayment
  /** payment value */
  value: WritableComputedRef<R>,
  /** public key for recurly js */
  publicKey: Ref<string> | string
}) {
  const currentInstance = getCurrentInstance()!
  const instance = currentInstance.proxy
  const store = instance.$store as IrisStore

  const data = reactive({
    recurlyData: null as null | RecurlyChangeResult,
    processing: false,
    cardAuthActive: false,
    error: null as null | Error | string,
    /** user has started inputting data */
    userHasStartedInput: false,
    userErrors: []
  })

  // const cardElement = ref<>(null)
  watch(() => data.recurlyData ? data.recurlyData.focus : false, (focus) => {
    if (!focus) {
      data.userHasStartedInput = true
    }
  })

  const userErrors = computed<string[]>(() => {
    if (data.userHasStartedInput && data.recurlyData) {
      return [
        !data.recurlyData.number.valid && `Card number is ${data.recurlyData.number.empty ? 'empty' : 'invalid'}`,
        !data.recurlyData.expiry.valid && `Expiry Date is ${data.recurlyData.expiry.empty ? 'empty' : 'invalid'}`,
        !data.recurlyData.cvv.valid && `CVV is ${data.recurlyData.cvv.empty ? 'empty' : 'invalid'}`
      ].filter((m): m is string => !!m)
    }
    return []
  })

  const canEdit = computed<boolean>(() => {
    return !(value.value.finalised || data.processing)
  })

  const payerDetails = computed< IPersonInfo & IAddressInfo>(() => {
    return store.getters.getInfoForPayerType(value.value.paidBy)
  })

  const cardDetailsOk = computed<boolean>(() => {
    return (data.recurlyData ? data.recurlyData.valid : false) && Object.keys(instance.$v).filter(k => k[0] !== '$' && k !== 'finalised').every(key => !instance.$v[key].$invalid)
  })

  /** process a valid payment once card details are good */
  const processPayment = () => {
    data.processing = true
    data.error = null
    return new Promise<TokenPayload>((resolve, reject) => {
      // overloads as want same return type as input type
      function truncateTo50 (str: string): string
      function truncateTo50 (str: undefined): undefined
      function truncateTo50 (str: string | undefined) {
        return str ? str.substring(0, 50) : str
      }
      recurly.token(elementsInstance!, {
        first_name: truncateTo50(payerDetails.value.firstName),
        last_name: truncateTo50(payerDetails.value.lastName),
        address1: truncateTo50(payerDetails.value.address1),
        address2: truncateTo50(payerDetails.value.address2),
        city: truncateTo50(payerDetails.value.city),
        country: truncateTo50(payerDetails.value.country),
        postal_code: truncateTo50(payerDetails.value.postcode)
      }, (err, arg) => {
        return err ? reject(err) : resolve(arg)
      })
    }).then((token) => {
      // got tokenised card number from recurly.

      // get / create recruly account first
      return store.dispatch('setupRecurlyAccount').then((accountId: string) => {
        /** process payment on backend - needs to retry this twice */
        const processPaymentWithToken = (token: string, threeDSecureToken?: string) => {
          return store.nestApi.processInitialPayment({
            ...unref(paymentTypeProps),
            accountId,
            amountInCents: value.value.amountInCents,
            subscriberId: store.state.createAccountResult!.subscriberId,
            subscriptionType: store.getters.newSubscriptionType,
            token,
            threeDSecureToken
          })
        }
        /** axios response is a 3d secure request */
        const isThreeDSecureError = (e: any): e is AxiosError<RecurlyTransactionError> & Required<Pick<AxiosError<RecurlyTransactionError>, 'response'>> => {
          return axios.isAxiosError(e) && e.response && e.response.data && e.response.status === 422 && e.response.data.code === 'three_d_secure_action_required' && e.response.data.threeDSecureActionTokenId
        }

        return processPaymentWithToken(token.id)
          .catch((error) => {
            if (isThreeDSecureError(error)) {
              data.cardAuthActive = true
              // const token = error.response.data.threeDSecureActionTokenId
              // debugger
              // data.error = error.response.data
              // 3d secure code
              return instance.$nextTick().then(() => {
                return new Promise<{
                    id: string
                    type: string
                  }>((resolve, reject) => {
                    const risk = recurly.Risk()
                    const threeDSecure = risk.ThreeDSecure({
                      actionTokenId: error.response.data.threeDSecureActionTokenId
                    })
                    threeDSecure.attach(currentInstance.proxy.$refs.authContainer as HTMLElement)
                    threeDSecure.on('error', reject)
                    threeDSecure.on('token', resolve)
                  })
              })
                .then(result => {
                  // console.log(result)
                  data.cardAuthActive = false
                  return processPaymentWithToken(token.id, result.id)
                }).catch(e => {
                  data.cardAuthActive = false
                  throw e
                })
            } else {
              throw error // not 3ds request error just pass through
            }
          }) // end catch 1st error on processing payment for 3ds
      })
        .then((result) => {
          element!.remove()
          element!.off()
          // mark and save receipt number
          value.value = {
            ...value.value,
            receiptNumber: result.receiptNumber,
            billingMethodId: result.billingMethodId,
            cardExpiryMonth: result.cardExpiryMonth,
            cardExpiryYear: result.cardExpiryYear,
            cardFirstSix: result.cardFirstSix,
            cardLastFour: result.cardLastFour,
            cardType: result.cardType,
            finalised: true
          }
          // console.log(result)
          return Promise.resolve(onSuccessfulPayment ? onSuccessfulPayment(result) : undefined)
        })
    }) // end of getting tokenised from recurly then
      .catch((error) => {
        // other error unpack response for message
        if (axios.isAxiosError(error) && error.response && error.response.data) {
          data.error = error.response.data
        } else {
          data.error = error
        }
        // instance.error = error
        Sentry.captureException(error)
      }).then(() => {
        data.processing = false
      })
  } // end processPayment

  const createElements = () => {
    const elements = recurly.Elements()
    elementsInstance = elements
    // let elements = instance.$stripe!.elements({ locale: 'en' })

    element = elements.CardElement({
      style: {
        lineHeight: '24px',
        placeholder: {
          color: '#495057'
        }
      }
      // style: {
      //   fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      //   lineHeight: '150%'

      // }
    })
    // debugger
    element.attach(currentInstance.proxy.$refs.cardElement as HTMLElement)
    element.on('change', (state: RecurlyChangeResult) => {
      // TODO reset local state if token as already been done.
      // console.log('recurly change event fired!')
      data.recurlyData = state
    })
    // element.on('submit', ({ state } : { state: RecurlyChangeResult }) => {
    //   console.log('SUBMIT', state)
    // })
  }

  let element: CardElement | undefined
  let elementsInstance: ElementsInstance | undefined

  onMounted(() => {
    if (!value.value.finalised) {
      return externalScript<Recurly>('https://js.recurly.com/v4/recurly.js', 'recurly', false).then(() => {
        // console.log('loaded recurly')
        // console.log(result)
        recurly.configure({
          publicKey: unref(publicKey)
        })
        createElements()
      //   const elements = recurly.Elements()
      //   const card = elements.CardElement({
      //     style: {
      //       // inputType: 'mobileSelect',
      //       fontColor: '#010101'
      //     }
      //   })
      //   card.attach(instance.refs['card-element'] as HTMLElement)
      })
    }
  })

  onUnmounted(() => {
    if (element) {
      element.remove()
      element.off()
    }
    // element.emit('destroy')
    // TODO detach recurly event handlers
  })

  return {
    processPayment,
    cardDetailsOk,
    userErrors,
    canEdit,
    processing: toRef(data, 'processing'),
    cardAuthActive: toRef(data, 'cardAuthActive'),
    error: toRef(data, 'error')
  }
}
